import React from 'react';
import Container, { ContainerProps } from '@/components/Container';
import { findAnchors } from '@/helpers/sanity.js';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export interface AnchorProps {
  blocks: Array<Record<string, any>>;
  lightModeSupport?: boolean;
}

const Anchors: React.FC<AnchorProps & ContainerProps> = ({
  blocks,
  lightModeSupport = true,
  ...any
}) => {
  // PurgeCSS keep: md:w-1/3 md:w-1/4 lg:w-1/3 lg:w-1/4 lg:w-1/5

  const { t } = useTranslation();
  const anchors = findAnchors(blocks);

  if (anchors?.length < 2) return <></>;

  const cols = (function (size) {
    if (size === 3 || (size > 4 && size <= 6)) return [3, 3];

    if (size >= 4 && size <= 8) return [4, 4];

    return [3, 5];
  })(anchors.length).map((item) => Math.min(anchors.length, item));

  const clickAnchor: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (e.currentTarget instanceof HTMLAnchorElement) {
      const href = e.currentTarget.getAttribute('href');
      const parts = href?.split('#');

      if (parts && parts.length > 0) {
        const hash = parts.pop();
        const el = hash ? document.getElementById(hash.trim()) : undefined;

        if (el) {
          e.preventDefault();
          el?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  };

  return (
    <Container style={{ paddingLeft: 0, paddingRight: 0 }} {...any}>
      <div
        className={`flex flex-wrap rounded-sm transform-gpu overflow-hidden font-normal p-px text-sm sm:text-base grid-cols-2 xl:text-lg`}
        aria-label={t('Contents')}
      >
        {anchors.map(({ slug, children }) => (
          <div
            key={slug}
            className={`flex items-stretch justify-center w-1/2 p-px grow md:w-1/${cols[0]} lg:w-1/${cols[1]}`}
          >
            <a
              onClick={clickAnchor}
              className={`flex items-center justify-center w-full text-center transition-colors backdrop-filter backdrop-blur-xl px-sm-fluid py-base hover:no-underline lg:p-base-fluid ${
                lightModeSupport
                  ? 'bg-white bg-opacity-70 hover:bg-opacity-100 dark:text-white dark:text-opacity-70 dark:bg-blue-950 dark:bg-opacity-40 dark:hover:bg-opacity-50 dark:hover:text-opacity-100'
                  : 'text-white text-opacity-70 bg-blue-950 bg-opacity-40 hover:bg-opacity-50 hover:text-opacity-100'
              }`}
              href={`#${slug}`}
            >
              <span>{children}</span>
            </a>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Anchors;
