import React from 'react';
import Card, { CardProps } from '@/components/Card';
import DivisionLogo from '@/components/DivisionLogo';
import Border from '@/components/Border';

export interface DivisionCardProps {
  division?: string;
}

const DivisionCard: React.FC<
  DivisionCardProps & CardProps & React.HTMLAttributes<HTMLDivElement>
> = ({ division, children, ...any }) => {
  return (
    <Card {...any}>
      <div className="flex items-center mb-auto overflow-x-hidden">
        {division && (
          <>
            <div className="sr-only">{division}</div>
            <DivisionLogo
              className="flex-none w-auto mr-base h-sm lg:h-base"
              division={division}
              aria-hidden="true"
            />
          </>
        )}
        <Border className="flex-1 transition-all duration-500 ease-out transform-gpu group-hover:translate-x-2xs" />
      </div>
      {children}
    </Card>
  );
};

export default DivisionCard;
