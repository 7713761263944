import * as React from 'react';
import { graphql } from 'gatsby';
import Seo from '@/components/Seo';
import Layout from '@/layouts/default';
import Container from '@/components/Container';
import MetalHeading from '@/components/MetalHeading';
import DivisionLogo from '@/components/DivisionLogo';
import Border from '@/components/Border';
import BlockContent from '@/components/BlockContent';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import BlockContentContainer from '@/components/BlockContentContainer';
import StoryCard from '@/components/StoryCard';
import Link from '@/components/Link';
import { replacer } from '@/helpers/string.js';
import { Routes } from '@/enums/routes.enum.js';
import StoryCarousel from '@/components/StoryCarousel';
import Eyebrow from '@/components/Eyebrow';
import Anchors from '@/components/Anchors';
import BeneathHeader from '@/components/BeneathHeader';
import Cover, { CoverProps } from '@/components/Cover';

export interface StoryTemplateProps {
  pageContext: {
    language: string;
    languageLinks: LanguageLinks;
  };
  data: {
    story: Pick<
      GatsbyTypes.SanityStory,
      | 'id'
      | 'eyebrow'
      | 'title'
      | 'summary'
      | 'i18n_lang'
      | '_rawBody'
      | 'theme'
    > & {
      readonly slug: Pick<GatsbyTypes.SanitySlug, 'current'>;
      readonly division: Pick<GatsbyTypes.SanityDivision, 'key'> & {
        readonly slug: Pick<GatsbyTypes.SanitySlug, 'current'>;
      };
      readonly meta: Pick<GatsbyTypes.SanityMeta, 'title' | 'description'>;
      readonly mainMedia: CoverProps['media'];
      readonly readingTime: GatsbyTypes.Maybe<
        Pick<GatsbyTypes.ReadingTime, 'minutes'>
      >;
    };
  } & Pick<GatsbyTypes.StoryPageQuery, 'stories' | 'nextStory'>;
}

const StoryTemplate: React.FC<StoryTemplateProps> = ({
  pageContext: { languageLinks },
  data: { story, nextStory, stories },
}) => {
  const { t } = useTranslation();

  return (
    <Layout languageLinks={languageLinks} theme={story.theme}>
      <Seo
        title={story!.meta!.title!}
        description={story!.meta!.description ?? story!.summary}
        image={story!.mainMedia}
      />
      <BeneathHeader>
        <header className="relative text-white bg-blue-900 mb-lg-fluid">
          <div className="absolute inset-0 z-0 bg-blue-900 opacity-30">
            <Cover
              className="absolute inset-0"
              media={story.mainMedia}
              width={1920}
              height={1080}
            />
          </div>
          <Container className="relative z-10 grid pt-6xl-fluid gap-x-gutter gap-y-xl-fluid pb-3xl-fluid md:grid-cols-12">
            <div className="flex items-center mb-md-fluid md:flex-row-reverse md:col-span-4 md:col-start-9 md:my-sm-fluid xl:flex-col xl:justify-end xl:items-start xl:my-0 2xl:col-start-1 2xl:col-span-2 2xl:row-start-1">
              <Link
                to={replacer(Routes.Division, story!.division)}
                className="text-inherit"
              >
                <DivisionLogo
                  division={story!.division!.key!}
                  className="flex-none w-auto h-4 max-w-full md:h-5 lg:h-6"
                />
              </Link>
              <Border
                className="flex-1 ml-base md:ml-0 md:mr-base lg:mb-xs-fluid lg:mt-base-fluid xl:flex-none xl:w-full"
                flex={false}
              />
            </div>
            <div className="md:col-span-8 md:col-start-1 md:row-start-1 2xl:col-start-3">
              {story!.eyebrow && (
                <div className="font-normal text-blue-500 mb-md-fluid text-2xl-fluid">
                  {story!.eyebrow}
                </div>
              )}
              <MetalHeading lightModeSupport={false} className="my-0 text-hero">
                {story!.title!}
              </MetalHeading>
            </div>
            <div className="md:col-span-8 2xl:col-start-3 2xl:row-start-2">
              {story!.summary && (
                <p className="my-0 text-xl-fluid max-w-prose-sm">
                  {story!.summary}
                </p>
              )}
            </div>
            <div className="text-sm font-normal text-blue-500 -mt-md-fluid md:mt-0 md:text-lg-fluid md:row-start-2 md:col-span-4 md:col-start-9 lg:flex lg:flex-col lg:text-base-fluid lg:justify-start 2xl:col-start-11 2xl:col-span-2 2xl:row-start-1 2xl:justify-end">
              <div title={t('Reading time')}>
                <span className="sr-only">
                  <Trans>Reading time</Trans>:{' '}
                </span>
                <Trans
                  count={Math.ceil(
                    Math.max(1, story.readingTime?.minutes ?? 0),
                  )}
                  i18nKey="readingTime"
                />
              </div>
              <Border
                className="hidden xl:mb-xs-fluid xl:mt-base-fluid 2xl:w-full 2xl:block"
                flex={false}
              />
            </div>
          </Container>
          <Anchors
            lightModeSupport={false}
            className="relative z-40"
            blocks={story!._rawBody ?? []}
          />
        </header>
      </BeneathHeader>

      <BlockContentContainer>
        <BlockContent isOnlyContent={false} blocks={story!._rawBody ?? []} />
      </BlockContentContainer>

      {stories.edges.length > 0 ? (
        <>
          <Container className="mt-3xl-fluid mb-md-fluid">
            <Eyebrow className="text-blue-500 mb-sm-fluid" uppercase={true}>
              {t('Next', { context: 'coming' })}
            </Eyebrow>
          </Container>
          <StoryCarousel stories={stories.edges} />
        </>
      ) : (
        <>
          {nextStory && (
            <Container bleed={true} className="mt-3xl-fluid">
              <Eyebrow
                className="text-blue-500 px-lg-fluid mb-md-fluid"
                uppercase={true}
              >
                {t('Next', { context: 'coming' })}
              </Eyebrow>
              <div className="aspect-w-16 aspect-h-16 sm:aspect-h-12 md:aspect-h-10 lg:aspect-h-8">
                <div className="grid">
                  <StoryCard story={nextStory} large={true} />
                </div>
              </div>
            </Container>
          )}
        </>
      )}
    </Layout>
  );
};

export default StoryTemplate;

export const query = graphql`
  query StoryPage($language: String!, $id: String!, $nextId: String) {
    story: sanityStory(id: { eq: $id }) {
      id
      theme
      eyebrow
      title
      summary
      i18n_lang
      slug {
        current
      }
      division {
        key
        slug {
          current
        }
      }
      meta {
        title
        description
      }
      mainMedia {
        ...SanityCoverFields
      }
      readingTime {
        minutes
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
    stories: allSanityStory(
      filter: {
        i18n_lang: { eq: $language }
        hasParentItem: { eq: true }
        parentItem: { id: { eq: $id } }
      }
    ) {
      edges {
        node {
          ...StoryCardFields
        }
      }
    }
    nextStory: sanityStory(id: { eq: $nextId }) {
      ...StoryCardFields
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...LocaleStrings
    }
  }
`;
