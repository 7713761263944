import React, { useMemo } from 'react';
import Carousel from '@/components/Carousel';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import StoryCard from '@/components/StoryCard';
import { SwiperSlide } from 'swiper/react';
import VideoOverlayCard from './VideoOverlayCard';

export interface StoryCarouselProps {
  stories: ReadonlyArray<{
    node: GatsbyTypes.StoryCardFieldsFragment;
  }>;
  className?: string;
  introVideo?: GatsbyTypes.SanityVideoReference;
}

const StoryCarousel: React.FC<StoryCarouselProps> = ({
  stories,
  introVideo,
  ...any
}) => {
  if (!stories.length) return <></>;

  const { t } = useTranslation();

  const cards: { id: string; node: React.ReactNode }[] = useMemo(() => {
    const _cards = stories.map(({ node }) => ({
      id: node.id,
      node: (
        <StoryCard story={node} large={true} className="w-full shadow-2xl" />
      ),
    }));

    if (introVideo) {
      _cards.splice(2, 0, {
        id: introVideo._key ?? 'introVideo',
        node: (
          <VideoOverlayCard
            large={true}
            title={introVideo.title!}
            preview={introVideo.preview}
            mp4={introVideo.video_mp4?.asset?.url}
            webm={introVideo.video_webm?.asset?.url}
          />
        ),
      });
    }

    return _cards;
  }, [stories, introVideo]);

  return (
    <Carousel
      singularLabel={t('Story')}
      navigation={true}
      bleed={true}
      freemode={true}
      sticky={true}
      {...any}
    >
      {cards.map(({ node, id }) => (
        <SwiperSlide
          key={id}
          className="flex items-stretch self-stretch w-72 pr-sm-fluid min-h-2xl xs:w-80 sm:w-96 md:w-auto md:max-w-screen-sm lg:min-h-3xl xl:max-w-screen-md xl:min-h-4xl 3xl:min-h-5xl"
          style={{ height: 'auto' }}
        >
          {node}
        </SwiperSlide>
      ))}
    </Carousel>
  );
};

export default StoryCarousel;
