import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface PortalProps {
  className?: string;
  children: React.ReactNode;
  tag?: string;
}

const Portal: React.FC<PortalProps> = ({
  children,
  className,
  tag = 'div',
}) => {
  const [container] = React.useState(document.createElement(tag));

  if (className) container.classList.add(className);

  React.useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  return ReactDOM.createPortal(children, container);
};

export default Portal;
