import React, { useCallback, useEffect } from 'react';
import Portal from '@/components/Portal';
import CloseIcon from '@resources/svg/icons/close.svg';

interface OverlayProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
  onClose?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Overlay: React.FC<OverlayProps> = ({
  children,
  className,
  title,
  onClose,
}) => {
  const escape = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' && onClose) onClose();
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('keyup', escape, false);

    return () => {
      document.removeEventListener('keyup', escape, false);
    };
  }, [escape]);

  return (
    <Portal>
      <div
        className={`z-100 overflow-y-auto fixed inset-0 flex flex-col bg-black text-white text-opacity-75 bg-opacity-75 backdrop-blur-lg ${
          className ?? ''
        }`}
      >
        <header className="flex items-start font-normal px-gutter space-x-md py-gutter sm:text-lg lg:text-xl">
          <div className="self-center flex-1 flex-shrink text-ellipsis">
            {title}
          </div>
          <button
            type="button"
            className="inline-block p-xs"
            onClick={(e) => onClose && onClose(e)}
          >
            <CloseIcon className="w-5 h-5 md:w-6 md:h-6" />
            <span className="sr-only">Close</span>
          </button>
        </header>
        <div className="flex flex-col items-center justify-center flex-1">
          {children}
        </div>
      </div>
    </Portal>
  );
};

export default Overlay;
