import React, { useState } from 'react';
import DivisionCard from '@/components/DivisionCard';
import { CardProps } from '@/components/Card';
import Overlay from '@/components/Overlay';
import Cover from '@/components/Cover';
import PlayIcon from '@resources/svg/icons/play.svg';

export interface VideoOverlayCardProps {
  eyebrow?: string;
  title: string;
  mp4?: string;
  webm?: string;
  preview?: GatsbyTypes.SanityCover;
  large?: boolean;
}

const VideoOverlayCard: React.FC<
  VideoOverlayCardProps &
    Pick<CardProps, 'media'> &
    React.HTMLAttributes<HTMLDivElement>
> = ({ eyebrow, large = false, title, preview, mp4, webm, className, ...any }) => {
  const [overlayOpened, setOverlayOpened] = useState(false);

  return (
    <DivisionCard
      division="duotec"
      className={`group flex flex-col min-h-xl leading-snug cursor-pointer sm:min-h-lg lg:min-h-lg xl:min-h-xl ${
        className ?? ''
      } ${large ? 'sm:min-h-2xl lg:min-h-3xl' : ''}`}
      contentClassName="flex flex-col items-stretch justify-center"
      onClick={() => setOverlayOpened(true)}
      media={
        preview ? (
          <Cover
            className="z-0 w-full h-full"
            width={large ? 1600 : 800}
            height={large ? 900 : 450}
            media={preview}
          />
        ) : undefined
      }
      {...any}
    >
      <div className="flex flex-col items-start justify-center py-10 md:py-12 lg:py-16">
        <PlayIcon className="w-7 h-7 md:w-10 md:h-10 lg:w-12 lg:h-12" />
      </div>
      <h2
        className={`font-semibold text-2xl max-w-prose-2xs mt-0 mb-sm leading-tight ${
          large
            ? 'sm:font-light sm:text-4xl-fluid sm:leading-none'
            : 'sm:text-lg sm:leading-tight md:text-2xl md:leading-tight lg:text-2xl lg:leading-tight 3xl:text-3xl 3xl:leading-tight'
        }`}
      >
        {title}
      </h2>
      {overlayOpened && (
        <Overlay
          title={title}
          onClose={(e) => {
            e?.stopPropagation();
            setOverlayOpened(false);
          }}
        >
          <div className="w-full max-w-7xl">
            <div className="bg-black shadow-2xl aspect-w-16 aspect-h-9">
              <video preload="auto" controls={true} autoPlay={true}>
                {mp4 && <source src={mp4} type="video/mp4" />}
                {webm && <source src={webm} type="video/webm" />}
              </video>
            </div>
          </div>
        </Overlay>
      )}
    </DivisionCard>
  );
};

export default VideoOverlayCard;
